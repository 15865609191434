import * as React from "react"
import { graphql } from 'gatsby'

import Meta from '../../components/meta'
import Layout from '../../components/layout'
import BlogListing from '../../components/blogListing'


const Highlight = ({ data }) => (
    <Layout green={true}>
        <Meta
            title="The Best Design Software"
            description="Read our guides to find the best design tool"
            url="/guides"
        />

        <BlogListing
            colorFlag="purple"
            headline="Find the Best Software for UI/UX Design"
            description="Have you ever wondered what the best software for UI/UX design is? Maybe you’ve used Photoshop in the past and were able to achieve decent results. But, there are many other programs on the market..."
            link="/best-for-ui-ux/"
        />

        <BlogListing
            colorFlag="green"
            headline="Find the Best Figma Alternative"
            description="If you’re a web designer, an app developer, or someone who’s just looking to build something great, there’s a chance that you might be looking for a good Figma alternative. Figma is a great tool for designing websites and apps, but..."
            link="/figma-alternative/"
        />

        <BlogListing
            colorFlag="purple"
            headline="Find the Best Online Design Software"
            description="We highlight the best design tools that run entirely on your web browser. All of the software showcased here just work seamlessly on the web and on all operating systems..."
            link="/highlight/"
        />

        <BlogListing
            colorFlag="green"
            headline="Find the Best Design Software for You"
            description="Are you confused about the difference between Figma, Uizard, or Photoshop? Do you want to find the perfect application that addresses both your needs and your experience? If you’ve ever found yourself asking something along..."
            link="/best-choice-for-you/"
        />
    </Layout>
  )

  export default Highlight;

  export const query = graphql`
  query {
  
    canvaImage: file(relativePath: { eq: "canva_screenshot.png" }) {
      childImageSharp {
        fluid(maxWidth: 2880, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    figmaImage: file(relativePath: { eq: "figma_screenshot.png" }) {
        childImageSharp {
          fluid(maxWidth: 2880, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
    }
    
    uizardImage: file(relativePath: { eq: "uizard_screenshot.png" }) {
        childImageSharp {
          fluid(maxWidth: 2880, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
    }

    pitchImage: file(relativePath: { eq: "pitch_screenshot.png" }) {
        childImageSharp {
          fluid(maxWidth: 2880, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
    }

  }
`;
