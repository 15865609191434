import * as React from "react"
import Styled from 'styled-components/macro';

import Headline from './headline'
import Description from './description'
import Content from './content'
import Container from './container'
import Button from './button'


const HeadlineExtended = Styled.h3`
    font-weight: 600;
    color: inherit;
    letter-spacing: -0.3px;
    margin: 0 0 1.5rem 0;
    font-size: 3rem;
    line-height: 4rem;
`;

const ContainerExtended = Styled(Container)`
    padding: 120px 0;
`;

const Text = Styled.div`
`;

const Row = Styled.div`
`;

const NavButtonLink = Styled.a`
  cursor: pointer;
  display: block;
  margin-top: 3rem;
`;

const ExtendedButton = Styled(Button)`
`;

export default ({colorFlag, headline, description, link}) => (
    <ContainerExtended purple={colorFlag == "purple" ? true : false} green={colorFlag == "green" ? true : false}>
        <Content>
                    <Row>
                        <Text>
                            <HeadlineExtended>{headline}</HeadlineExtended>
                            <Description>
                                {description}
                            </Description>

                            <NavButtonLink href={link}>
                                <ExtendedButton green={colorFlag == "green" ? true : false}>
                                    Read
                                </ExtendedButton>
                            </NavButtonLink>
                        </Text>
                    </Row>
        </Content>
    </ContainerExtended>
)